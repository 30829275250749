import React from 'react';

import { useSelector } from 'react-redux';

import { useRoutes } from 'react-router-dom';

import { Status } from 'interfaces/api.interface';
import MainLayout from 'layouts/MainLayout';
import Main from 'pages/Main/Main';

import { selectUser } from 'redux/user/selectors';

const App: React.FC = () => {
  const { status, isAuth } = useSelector(selectUser);

  let element = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Main />,
        },
      ],
    },
  ]);

  return isAuth && [Status.IDLE, Status.LOADING].includes(status) ? <div>Loading...</div> : element;
};

export default App;
