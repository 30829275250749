import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { ReactComponent as BirthdayIcon } from 'assets/icons/birthday.svg';
import { ReactComponent as PoleIcon } from 'assets/icons/pole.svg';

const Sidebar: React.FC = () => {
  return (
    <div className={styles.sidebar}>
      <Link to="/" className={styles.active}>
        <PoleIcon />
      </Link>

      <Link to="/">
        <BirthdayIcon />
      </Link>

      <Link to="/">
        <PoleIcon />
      </Link>

      <Link to="/">
        <BirthdayIcon />
      </Link>

      <Link to="/">
        <PoleIcon />
      </Link>

      <Link to="/">
        <BirthdayIcon />
      </Link>
    </div>
  );
};

export default Sidebar;
