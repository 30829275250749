import React from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as FaqIcon } from 'assets/icons/faq.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as VkIcon } from 'assets/icons/vk.svg';
import { ReactComponent as WarrantyIcon } from 'assets/icons/warranty.svg';

import Logo from 'components/UI/Logo/Logo';

type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className={styles.header}>
      <Link to="/" className={styles.logotype}>
        <Logo />
      </Link>

      <div className={styles.nav}>
        <Link to="/" className={styles.active}>
          <HomeIcon />
          Главная
        </Link>
        <Link to="/">
          <WarrantyIcon />
          Гарантии
        </Link>
        <Link to="/">
          <FaqIcon />
          FAQ
        </Link>
        <Link to="/">
          <SendIcon />
          Контакты
        </Link>
      </div>
      <div className={styles.rightbar}>
        <button className={styles.notify}>
          <BellIcon />
          <span />
        </button>

        <div className={styles.socials}>
          <button className={styles.vk}>
            <VkIcon />
          </button>

          <button className={styles.facebook}>
            <FacebookIcon />
          </button>

          <button className={styles.telegram}>
            <TelegramIcon />
          </button>
        </div>

        <button className={styles.auth}>
          Войти
          <span>
            <LockIcon />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Header;
