import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import ProfileAvatar from 'assets/images/profile.png';

const Chat: React.FC = () => {
  return (
    <div className={styles.message}>
      <Link to="/">
        <img alt="userName" src={ProfileAvatar} />
        <div className={styles.time}>02:43</div>
      </Link>
      <div className={styles.info}>
        <div className={styles.name}>Никита</div>
        <div className={styles.text}>
          Самое интересное это то что этот чат вмещает в себе достаточное количество смс-сообщений, и даже можно
          отправить репорт.
        </div>
      </div>
    </div>
  );
};

export default Chat;
