import React from 'react';

import { Outlet } from 'react-router-dom';

import styles from './styles.module.scss';

import Chat from 'components/Chat/Chat';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';

const MainLayout: React.FC = () => {
  return (
    <div>
      <Header />
      <div className={styles.main}>
        <div className={styles.app}>
          <Sidebar />

          <div className={styles.content}>
            <Outlet />
          </div>

          <Footer />
        </div>
        <Chat />
      </div>
    </div>
  );
};

export default MainLayout;
