import { createSlice } from '@reduxjs/toolkit';

import { Status } from 'interfaces/api.interface';
import { UserSliceState } from 'redux/user/types';
import axios from 'utils/axios';

const initialState: UserSliceState = {
  data: undefined,
  status: Status.IDLE,
  token: window.localStorage.token,
  isAuth: !!window.localStorage.token,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state) {
      state.data = {
        id: 1,
        name: 'Tommy',
        avatar: '123',
      };
      state.isAuth = true;
    },
    logout(state) {
      state.data = undefined;
      state.isAuth = false;

      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
