import React from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { ReactComponent as GamepadIcon } from 'assets/icons/gamepad.svg';
import { ReactComponent as ItemsIcon } from 'assets/icons/items.svg';
import { ReactComponent as LoveIcon } from 'assets/icons/love.svg';
import { ReactComponent as PingPonkIcon } from 'assets/icons/pingponk.svg';
import { ReactComponent as PoleIcon } from 'assets/icons/pole.svg';
import { ReactComponent as UserAddIcon } from 'assets/icons/useradd.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/world.svg';

import Gift from 'components/Banner/Gift/Gift';
import Pass from 'components/Banner/Pass/Pass';

const Main: React.FC = () => {
  return (
    <div>
      <div className={styles.banners}>
        <Gift />
        <Pass />
      </div>

      <div className={styles.games}>
        {[...Array(6)].map((x, i) => (
          <Link to="/" key={i}>
            <PoleIcon />
            <span>
              Монополия
              <br />
              2x2
            </span>
          </Link>
        ))}
      </div>

      <div className={styles.benefits}>
        <div className={styles.benefit}>
          <div className={styles.badge}>
            <LoveIcon />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Это бесплатно!</div>
            <div className={styles.description}>
              Просто создайте аккаунт и начните играть — никаких подписок и платежей.
            </div>
          </div>
        </div>

        <div className={styles.benefit}>
          <div className={styles.badge}>
            <GamepadIcon />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Множество режимов</div>
            <div className={styles.description}>Мы создали множество режимов в которых вы испытаете что-то новое.</div>
          </div>
        </div>

        <div className={styles.benefit}>
          <div className={styles.badge}>
            <PingPonkIcon />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Соревнования</div>
            <div className={styles.description}>
              Играйте в Соревновательном режиме, получайте звание и прокачивайте его.
            </div>
          </div>
        </div>

        <div className={styles.benefit}>
          <div className={styles.badge}>
            <UserAddIcon />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Новые друзья</div>
            <div className={styles.description}>Находите интересных вам игроков и добавляйте их в друзья.</div>
          </div>
        </div>

        <div className={styles.benefit}>
          <div className={styles.badge}>
            <ItemsIcon />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Коллекционирование</div>
            <div className={styles.description}>
              У нас имеется множество предметов, некоторые из которых очень редкие.
            </div>
          </div>
        </div>

        <div className={styles.benefit}>
          <div className={styles.badge}>
            <WorldIcon />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Играйте где угодно</div>
            <div className={styles.description}>Вы можете играть на любом устройстве, где есть браузер и интернет.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
