import React from 'react';

import ChatMessage from './Message/Message';

import styles from './styles.module.scss';

import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as OnlineIcon } from 'assets/icons/online.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as SmileIcon } from 'assets/icons/smile.svg';

const Chat: React.FC = () => {
  return (
    <div className={styles.chat}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.badge}>
            <ChatIcon />
          </div>
          Чат
        </div>

        <div className={styles.online}>
          <OnlineIcon />
          <span>23 895</span>
        </div>
      </div>
      <div className={styles.messages}>
        {[...Array(10)].map((x, i) => (
          <ChatMessage key={i} />
        ))}
      </div>
      <div className={styles.input}>
        <input type="text" placeholder="Введите ваше сообщение..." />
        <button className={styles.smile}>
          <SmileIcon />
        </button>
        <button className={styles.send}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default Chat;
