import React from 'react';

import styles from './styles.module.scss';

import { ReactComponent as GiftIcon } from 'assets/images/banners/gift/gift.svg';

const Gift: React.FC = () => {
  return (
    <div className={styles.gift}>
      <div className={styles.info}>
        <div className={styles.title}>
          Скромная мини
          <br /> коробка подарков
        </div>
        <div className={styles.description}>
          Открыв её, вы подарите трём случайным игрокам из
          <br /> общего чата сувенирные карточки со ссылкой на ваш профиль.
        </div>
      </div>
      <GiftIcon />
    </div>
  );
};

export default Gift;
