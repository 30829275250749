export interface ApiResponse {
  success: boolean;
  response?: any;
  error?: any;
}

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SAVING = 'saving',
  DELITING = 'deliting',
  SUCCESS = 'completed',
  ERROR = 'error',
  NOT_FOUND = 'not found',
}
