import React from 'react';

import styles from './styles.module.scss';

import WalletIcon from 'assets/images/banners/pass/wallet.png';

const Pass: React.FC = () => {
  return (
    <div className={styles.pass}>
      <div className={styles.head}>
        <div className={styles.badge}>
          <img alt="Сезонный пропуск" src={WalletIcon} />
        </div>
        Сезонный пропуск
      </div>
      <div className={styles.description}>
        Активировав сезонный пропуск, вы будете получать призы сезона, дополнительный опыт, памятные значки.
      </div>
    </div>
  );
};

export default Pass;
