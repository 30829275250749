import React from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { ReactComponent as DocsIcon } from 'assets/icons/docs.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as FaqIcon } from 'assets/icons/faq.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as VkIcon } from 'assets/icons/vk.svg';
import { ReactComponent as WarrantyIcon } from 'assets/icons/warranty.svg';
import Logo from 'components/UI/Logo/Logo';

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.logotype}>
        <Logo theme="secondary" />
        <div className={styles.description}>Rooms.ai — сервис с множеством бесплатных и интересных настольных игр.</div>
      </div>

      <div className={styles.nav}>
        <Link to="/">
          <DocsIcon />
          Соглашение
        </Link>
        <Link to="/">
          <WarrantyIcon />
          Гарантии
        </Link>
        <Link to="/">
          <FaqIcon />
          FAQ
        </Link>
        <Link to="/">
          <SendIcon />
          Контакты
        </Link>
      </div>

      <div className={styles.socials}>
        <button className={styles.vk}>
          <VkIcon />
        </button>

        <button className={styles.facebook}>
          <FacebookIcon />
        </button>

        <button className={styles.telegram}>
          <TelegramIcon />
        </button>
      </div>
    </div>
  );
};

export default Footer;
