import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from 'redux/store';
import { logout } from 'redux/user/slice';

/**
 * Api Instance
 */
export const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? `https://roams.ai/api` : `https://${document.location.hostname}/api/`,
  headers: {
    Accept: 'application/json',
  },
});

localStorage.getItem('token') &&
  (instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`);

// Перехват запросов
instance.interceptors.response.use(
  (response) => {
    response.data?.response?.notify &&
      toast[response.data?.response.notify.type](response.data?.response.notify.content);

    return response;
  },
  (error) => {
    const { dispatch } = store;

    const data = error.response.data;
    switch (error.response.status) {
      case 400:
        process.env.NODE_ENV === 'development' && console.log(error.response);
        for (let field in data.error) {
          for (let key in data.error[field]) {
            toast.error(`[${field}] ${data.error[field][key]}`);
          }
        }
        break;

      case 401:
        process.env.NODE_ENV === 'development' && console.log(error.response);
        dispatch(logout());
        break;

      case 403:
        if (error.request.responseURL.search(`api/v1/auth/me/`) !== -1) {
          console.log('redirect to 2fa');
        }
        break;

      case 404:
        toast.error(`Страница не найдена!`);
        break;

      default:
        break;
    }

    return error;
  },
);

export default instance;
